import React from 'react';
import {Row, Col} from 'antd';

export function GridLayout({children}) {
  return (
    <Row gutter={{xs: 12, md: 25, lg: 53}} style={{alignSelf: 'stretch'}}>
      {children}
    </Row>
  );
}

export function GridItem({children}) {
  return (
    <Col xs={12} md={8} lg={6}>
      {children}
    </Col>
  );
}
